import React, { useEffect, useState } from "react"
import styled from "styled-components"
import chroma from 'chroma-js';
import { useSwipeable } from 'react-swipeable';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { dimensions, colors } from '../shared/styles';

import DotBackground from '../components/dotbackground';
import Frame from '../components/frame';
import RoundButton from '../components/roundbutton';
import Section, { SectionWrapper } from '../components/section';

import content from '../data/static-content.js';

import { navigationTag } from '../shared/analytics';

const ApprocheSectionWrapperComponent = styled(SectionWrapper)`
  height : 100%;
  overflow : hidden;
`;

const ApprocheSection = styled(Section).attrs(() => ({
  WrapperComponent : ApprocheSectionWrapperComponent
}))`
  height : 100%;
  width : 100%;
  min-height : 80rem;
  max-height : 110rem;
  background-color : white;
  padding-top : ${dimensions.headerHeight};
  position : relative;
`;

const Background = styled(DotBackground)`
  position : absolute;
  top      : ${dimensions.headerHeight};
  left     : 6%;
  width    : 88%;
  height   : 85%;
`;

const Title = styled.h1`
  padding-left : 15rem;

  @media screen and (max-width: 425px) {
    padding-left : 3rem;
  }
`;

const ButtonsContainer = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : flex-end;
  margin-right : 18rem;

  @media screen and (max-width: 800px) {
    margin-right : 8rem;
  }

  @media screen and (max-width: 425px) {
    margin-right : 5rem;
    position : absolute;
    bottom : 5rem;
    width : 100%;
  }
`;

const NavigationButton = styled(RoundButton)`
  margin-right : 3rem;
`;


const TilesContainer = styled.div`

`;

const Tile = styled(Frame)`
  left : 15rem;
  margin-top : -4rem;
  width : 60rem;
  min-height : 30rem;
  position : absolute;

  transition : all 600ms ease;

  & .content-container {
    transition : all 600ms ease;
    padding : 6rem;
  }

  @media screen and (max-width: 425px) {
    width : 80%;
    left  : 10%;

    margin-top : 8rem;
  }

  @media screen and (max-width: 425px) and (max-height: 570px) {
    margin-top : 4rem;

    & .content-container {
      padding : 2.5rem;
    }
  }


  ${
    Object.keys(new Array(13).fill(0)).map(key => key - 6).map(
      offset => `
        &.offset${offset} {
          transform : translate(${offset*103}%, ${Math.abs(offset-1)*-20+20}%) ${offset ? ' scale(0.85)' : '' };
        }

        &.offset${offset} .content-container {
          border-color : ${chroma.scale([colors.blue, 'white']).domain([0,2.5])(Math.abs(offset))};
          color : ${chroma.scale(['black', 'white']).domain([0,2.5])(Math.abs(offset))};
        }
      `
    ).join('')
  }

`;

const TileTitle = styled.h2`
`;

const TileContent = styled.p`
  line-height : 3rem;
`;

const TileImages = styled.div`

`

const TileImage = styled.img`
  width : 6.4rem;
  height : 6.4rem;
  margin-left : 2rem;

  &:nth-child(1) {
    margin-left : 0rem;
  }
`;

const hashesDictionary = content.method.tiles.reduce(
  (hashes, tile, index) => {
    hashes['#'+tile.hash] = index;
    return hashes;
  },
  {}
);

const ApprochePage = () => {
  const [selected, setSelected] = useState(hashesDictionary[typeof window !== 'undefined' && window.location.hash] || 0);

  const nextHash = () => {
    if(selected + 1 < content.method.tiles.length ) {
      const hash = content.method.tiles[selected + 1].hash;

      navigationTag(`swipe-expertise-next->${hash}`);
      window.location.hash = hash;
    }
  }

  const prevHash = () => {
    if(selected > 0 ) {
      const hash = content.method.tiles[selected - 1].hash;

      navigationTag(`swipe-expertise-prev->${hash}`);
      window.location.hash = hash;
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft : nextHash,
    onSwipedRight : prevHash
  });

  useEffect(() => {
    function handleHashChange() {
      setSelected(hashesDictionary[window.location.hash] || 0)
    }

    window.addEventListener('hashchange', handleHashChange, false);

    return () => {
      window.removeEventListener('hashchange', handleHashChange, false)
    };
  }, [selected]);

  return (
    <Layout>
      <SEO title="Appunto" />
      <ApprocheSection>
        <Background />
        <Title>{content.method.title}</Title>
        <ButtonsContainer>
          <NavigationButton arrow="left" pinkDot={false} animatedArrow={false} onClick={prevHash} disabled={selected === 0}/>
          <NavigationButton animatedArrow={false} onClick={nextHash} disabled={selected === content.method.tiles.length-1}/>
        </ButtonsContainer>
        <TilesContainer {...handlers} >
          {
            content.method.tiles.map(
              (tile, index) =>
              <Tile
                key={tile.hash}
                tr br bl tl="pink"
                noShadow={index !== selected}
                className={`offset${index-selected}`}
                onClick={() => {
                  if(index < selected) {
                    prevHash()
                  }
                  if(index > selected) {
                    nextHash()
                  }
                }}
                >
                <TileTitle>{tile.title}</TileTitle>
                <TileContent>{tile.content}</TileContent>
                <TileImages>
                  {
                    tile.images.map(
                      (image, index) => <TileImage src={image} key={index} />
                    )
                  }
                </TileImages>
              </Tile>
            )
          }
        </TilesContainer>
      </ApprocheSection>
    </Layout>
  );
}

export default ApprochePage
