import React from "react"

const RoundButton = ({children, onClick, className = '', arrow = 'right', animatedArrow = true, pinkDot = true, disabled = false, style, ...props}) =>
  <button
    className={`round-button ${className} ${disabled ? 'disabled' : ''}`}
    style={style}
    onClick={!disabled ? onClick : undefined}
    {...props}
  >
    {
      pinkDot && !disabled && <div className="pink-dot"/>
    }
    <div className="background">
      {
        children ?
          <div className="content">
            {children}
          </div> : null
      }
      <div className={`arrow ${arrow} ${animatedArrow ? 'animated' : ''}`} />
    </div>
  </button>;

export default RoundButton;
